<template>
  <component :is="component"></component>
</template>

<script>
import { mapGetters } from 'vuex';
import PersonaConstants from '@/shared/constants/PersonaConstants';
export default {
  name: 'AnalyticsManager',

  components: {
    BuyerAnalytics: () => import('./BuyerAnalytics'),
    VendorAnalytics: () => import('./VendorAnalytics'),
    SuperAnalytics: () => import('./SuperAnalytics'),
  },

  computed: {
    ...mapGetters('auth', ['user']),
    component() {
      const { persona } = this.user || '';
      switch (persona) {
        case PersonaConstants.super.value:
          return 'SuperAnalytics';
        case PersonaConstants.vendor.value:
          return 'VendorAnalytics';
        case PersonaConstants.buyer.value:
        default:
          return 'BuyerAnalytics';
      }
    },
  },
};
</script>
